const accomodations = [
  {
    id: 1,
    category: "Hotel",
    image:
      "https://res.cloudinary.com/dnqhrr6jk/image/upload/f_auto,q_auto/v1/Lewen%20Tours/radisson1_gceval",
    title: "Radisson Blu Hotel & Convention Centre, Kigali",
    location: "Kigali City",
    starsNo: 4.5,
    reviewsNo: 1644,
    description: `The Radisson Blu Hotel & Convention Centre, Kigali features
                  291 rooms for business or leisure travelers, complete with
                  free Wi-Fi and in-room coffee and tea facilities.`,
  },
  {
    id: 2,
    category: "Hotel",
    image:
      "https://res.cloudinary.com/dnqhrr6jk/image/upload/f_auto,q_auto/v1/Lewen%20Tours/five2five_ex2kms",
    title: "Five To Five Hotel",
    location: "Remera, Kigali, Rwanda",
    starsNo: 4.5,
    reviewsNo: 518,
    description: `The Five to Five Hotel offers amenities such as free Wi-Fi, a fitness center, a rooftop terrace, a restaurant, and a bar.`,
  },
  {
    id: 3,
    category: "Hotel",
    image:
      "https://res.cloudinary.com/dnqhrr6jk/image/upload/f_auto,q_auto/v1/Lewen%20Tours/hotel-exterior_vgnmmn",
    title: "Kigali Marriott Hotel",
    location: "Nyarugenge, Kigali, Rwanda",
    starsNo: 4.5,
    reviewsNo: 638,
    description: `Kigali Marriott Hotel Kigali offers a range of amenities including a fitness center, outdoor pool, spa, multiple dining options, and meeting/event spaces.`,
  },
  {
    id: 4,
    category: "Hotel",
    image:
      "https://res.cloudinary.com/dnqhrr6jk/image/upload/f_auto,q_auto/v1/Lewen%20Tours/sheraton_ujfdzz",
    title: "Four Points by Sheraton Kigali",
    location: "Nyarugenge, Kigali, Rwanda",
    starsNo: 4.5,
    reviewsNo: 114,
    description: `Four Points by Sheraton Kigali features accommodation with an outdoor swimming pool, free private parking, a fitness centre and a terrace. With a restaurant, the 5-star hotel has air-conditioned rooms with free WiFi, each with a private bathroom`,
  },
  {
    id: 5,
    category: "Hotel",
    image:
      "https://res.cloudinary.com/dnqhrr6jk/image/upload/f_auto,q_auto/v1/Lewen%20Tours/cleo_odx8tz",
    title: "Cleo Lake Kivu Hotel",
    location: "Bwishyura, Karongi, Rwanda",
    starsNo: 4.5,
    reviewsNo: 114,
    description: `Cleo Lake Kivu Hotel offers a range of amenities including an outdoor pool, fitness center, spa, restaurant, bar, conference facilities, and free Wi-Fi.`,
  },
  {
    id: 6,
    category: "Apartment",
    image:
      "https://res.cloudinary.com/dnqhrr6jk/image/upload/f_auto,q_auto/v1/Lewen%20Tours/atlis_apart_pbmwzz",
    title: "Altis Apartments",
    location: "Bwishyura, Karongi, Rwanda",
    starsNo: 4.5,
    reviewsNo: 114,
    description: `Altis Apartments is an eight-storey building opening on a panoramic view of Kigali Mountain, historically giving name to the city. The apartments are fully furnished and located at a walking distance to major supermarket of Kigali`,
  },
  {
    id: 7,
    category: "Apartment",
    image: "/images/Hotels/cleo.jpg",
    title: "Grazia Hotel & Apartments",
    location: "Bwishyura, Karongi, Rwanda",
    starsNo: 4.5,
    reviewsNo: 114,
    description: `Grazia Hotel & Apartments is located near Kigali Convention Centre. It has 50 air-conditioned rooms featuring LED televisions. Your memory foam bed comes with Egyptian cotton sheets`,
  },
  {
    id: 8,
    category: "Apartment",
    image: "/images/Hotels/cleo.jpg",
    title: "Rentberry A",
    location: "Bwishyura, Karongi, Rwanda",
    starsNo: 4.5,
    reviewsNo: 114,
    description: `Rentberry is a platform that helps you find apartments available for rent in Kigali, Rwanda. You can discover apartments available for rent using their convenient search. Schedule a tour, apply online and secure your future apartment near Kigali, Rwanda`,
  },
  {
    id: 9,
    category: "Apartment",
    image: "/images/Hotels/cleo.jpg",
    title: "Rentberry",
    location: "Bwishyura, Karongi, Rwanda",
    starsNo: 4.5,
    reviewsNo: 114,
    description: `Rentberry is a platform that helps you find apartments available for rent in Kigali, Rwanda. You can discover apartments available for rent using their convenient search. Schedule a tour, apply online and secure your future apartment near Kigali, Rwanda`,
  },
];

  export default accomodations;