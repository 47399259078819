const top_travelist = [
  {
    id: 1,
    travel_image:
      "https://res.cloudinary.com/dnqhrr6jk/image/upload/f_auto,q_auto/v1/Lewen%20Tours/35174428511_73e9f81c21_k-1920x1280_kd9k9o",
    travel_title: "Gorilla Trekking",
    travel_text:
      "Venture into the heart of lush rainforests, where you'll have the chance to observe mountain gorillas in their natural habitat.",
    trave_details: {
      link: "gorilla-trekking",
      background_image:
        "/images/GolliraTrekking/view-wild-gibbon-apes-nature.jpg",
      description: `Welcome to Gorilla Trekking, where visitors to Rwanda's Mountain
            Gorillas don't just meet them but play an active role in their
            conservation by sharing their experiences. This is the first
            population of wild animals in the world to have an active social
            media presence that helps with vital research, management and
            protection. Using their Instagram photos, all of our visitors become
            citizen scientists during their visits by adding optional tags that
            help rangers, researchers and our monitoring teams keep track of the
            groups and individuals. This simple approach gives us access to a
            wealth of verified media content from our Gorilla communities every
            single day, including recording behaviour, group changes,
            interactions and alerting our rangers to any injuries or concerns.`,
      gallery: [
        {
          image:
            "https://res.cloudinary.com/delpedjbc/image/upload/f_auto,q_auto/v1/lewen%20tours/mvu7qq1vg05nragkwdru",
          public_id: "cute-small-gorilla",
        },
        {
          image:
            "https://res.cloudinary.com/delpedjbc/image/upload/f_auto,q_auto/v1/lewen%20tours/v1fpdmigvu9z4znkczn5",
          public_id: "mountain-gorillas-gorilla-beringei",
        },
        {
          image:
            "https://res.cloudinary.com/delpedjbc/image/upload/f_auto,q_auto/v1/lewen%20tours/jhtv9ia1zzn8kkemomvj",
          public_id: "view-gibbon-apes",
        },
        {
          image:
            "https://res.cloudinary.com/delpedjbc/image/upload/f_auto,q_auto/v1/lewen%20tours/c43brxnvawsqj3w3s4eh",
          public_id: "view-gorilla-sitting",
        },
        {
          image:
            "https://res.cloudinary.com/delpedjbc/image/upload/f_auto,q_auto/v1/lewen%20tours/nrse9y0wf7fdjfsxhpux",
          public_id: "view-wild-gibbon",
        },
        {
          image:
            "https://res.cloudinary.com/delpedjbc/image/upload/f_auto,q_auto/v1/lewen%20tours/avymqwb8ldxtygrfujrr",
          public_id: "view-gibbon-apes-rain",
        },
      ],
    },
  },
  {
    id: 2,
    travel_image:
      "https://res.cloudinary.com/dnqhrr6jk/image/upload/f_auto,q_auto/v1/Lewen%20Tours/closeup-shot-silver-car-approaching-giraffe-safari_a1dh7j",
    travel_title: "Game Drive",
    travel_text:
      "Embark on a thrilling journey through the untamed wilderness, where captivating encounters with Africa's magnificent creatures await.",
    trave_details: {
      link: "game-drive",
      background_image:
        "/images/GolliraTrekking/view-wild-gibbon-apes-nature.jpg",
      description: `Experience the thrill of a lifetime by embarking on a game drive safari in Rwanda's 
                    Akagera National Park. Our travel website offers a unique opportunity to explore the vast savannah 
                    landscapes of the park and view the stunning wildlife in their natural habitat. You can choose between 
                    morning or night game drives, accompanied by an experienced park ranger/guide who will help you locate 
                    specific wildlife species that you want to see. The park is home to over 8,000 large wildlife animals, 
                    including Africa's famous Big Five. We are committed to providing you with 
                    a memorable and safe experience that will leave you with unforgettable memories of Rwanda's natural beauty.`,
      gallery: [
        {
          image:
            "https://res.cloudinary.com/delpedjbc/image/upload/f_auto,q_auto/v1/lewen%20tours/fjhpczuzjqxruknx5wpd",
          public_id: "cute-small-chimpan1",
        },
        {
          image:
            "https://res.cloudinary.com/delpedjbc/image/upload/f_auto,q_auto/v1/lewen%20tours/nogndrvjtkk2sz8p73ks",
          public_id: "mountain-gorillas-gorilla-beringei12",
        },
        {
          image:
            "https://res.cloudinary.com/delpedjbc/image/upload/f_auto,q_auto/v1/lewen%20tours/rh1h49lrjwaz2uy8zvui",
          public_id: "view-gibbon-apes3",
        },
        {
          image:
            "https://res.cloudinary.com/delpedjbc/image/upload/f_auto,q_auto/v1/lewen%20tours/vbyfcirrjawdyqg7jyjl",
          public_id: "view-gorilla-sitting4",
        },
        {
          image:
            "https://res.cloudinary.com/delpedjbc/image/upload/f_auto,q_auto/v1/lewen%20tours/g4yhcoajokh0pflrekja",
          public_id: "view-wild-gibbon5",
        },
        {
          image:
            "https://res.cloudinary.com/delpedjbc/image/upload/f_auto,q_auto/v1/lewen%20tours/ux80enktbiqahlspa01s",
          public_id: "view-gibbon-apes-rain6",
        },
      ],
    },
  },
  {
    id: 3,
    travel_image:
      "https://res.cloudinary.com/dnqhrr6jk/image/upload/f_auto,q_auto/v1/Lewen%20Tours/cemetery-with-white-stone-crosses-surrounded-by-green-trees-cloudy-sky_wk2ept",
    travel_title: "Genocide Memorial Sites",
    travel_text:
      "The Genocide Memorial stands as a stark testament to the atrocities committed, honoring the lives lost and celebrating the unwavering spirit of those who survived",
    trave_details: {
      link: "genocide-memorial",
      background_image:
        "/images/GolliraTrekking/view-wild-gibbon-apes-nature.jpg",
      description: `The Rwanda Genocide Memorial Sites are a collection of locations throughout Rwanda that commemorate 
      the victims of the 1994 genocide against the Tutsi. These sites serve as a place of remembrance and learning, 
      where visitors can pay their respects to the victims and learn about the history of the genocide. The most notable of 
      these sites is <b>the Kigali Genocide Memorial</b>, which is a permanent national memorial located in Kigali, the capital city
       of Rwanda. The memorial is home to over 250,000 victims of the genocide and is open to the public every day from 
       9:00 AM to 4:00 PM 1. Other notable sites include the <b>Nyamata Genocide Memorial</b>, <b>the Murambi Genocide Memorial</b>,
        <b>the Gisozi Genocide Memorial</b>, and the <b>Bisesero Genocide Memorial</b>.
      <br/><br/>
      Our travel website offers a unique opportunity to visit these memorial sites and learn about the history of the genocide.
       We provide guided tours to all of the major memorial sites in Rwanda, including the Kigali Genocide Memorial, Nyamata
        Genocide Memorial, Murambi Genocide Memorial, Gisozi Genocide Memorial, and Bisesero Genocide Memorial. Our experienced
         guides will help you navigate the sites and provide you with a comprehensive understanding of the history of the 
         genocide. We are committed to providing you with a safe and memorable experience that will leave you with a deeper
          appreciation of Rwanda's history and culture. Please contact us to learn more about our services and to book
           your tour today!`,
      gallery: [
        {
          image:
            "https://res.cloudinary.com/delpedjbc/image/upload/f_auto,q_auto/v1/lewen%20tours/fgzu544ywbwt7rrqi5av",
          public_id: "cute-small-chimpan6",
        },
        {
          image:
            "https://res.cloudinary.com/delpedjbc/image/upload/f_auto,q_auto/v1/lewen%20tours/sb1ggafhl3a0e4whspil",
          public_id: "mountain-gorillas-gorilla-beringei5",
        },
        {
          image:
            "https://res.cloudinary.com/delpedjbc/image/upload/f_auto,q_auto/v1/lewen%20tours/wangzo3diloocukra5vh",
          public_id: "view-gibbon-apes4",
        },
        {
          image:
            "https://res.cloudinary.com/delpedjbc/image/upload/f_auto,q_auto/v1/lewen%20tours/xjcfvg1zvnvqzlme2rs3",
          public_id: "view-gorilla-sitting3",
        },
        {
          image:
            "https://res.cloudinary.com/delpedjbc/image/upload/f_auto,q_auto/v1/lewen%20tours/xnbw4jxvzx1ftwoymafh",
          public_id: "view-wild-gibbon2",
        },
        {
          image:
            "https://res.cloudinary.com/delpedjbc/image/upload/f_auto,q_auto/v1/lewen%20tours/i8jfglxpvjcvfuw9nwnc",
          public_id: "view-gibbon-apes-rain1",
        },
      ],
    },
  },
  {
    id: 4,
    travel_image:
      "https://res.cloudinary.com/dnqhrr6jk/image/upload/f_auto,q_auto/v1/Lewen%20Tours/wnfchkscaakfhdqk2weq",
    travel_title: "Biking and Hiking",
    travel_text:
      "Unleash your adventurous spirit and immerse yourself in the captivating beauty of Rwanda's landscapes",
    trave_details: {
      link: "biking-and-hiking",
      background_image:
        "/images/GolliraTrekking/view-wild-gibbon-apes-nature.jpg",
      description: ` Rwanda is a country of stunning natural beauty, and there's no better way to experience 
      it than by biking or hiking. Our travel website offers a unique opportunity to explore the country's breathtaking
       landscapes and natural wonders. We provide guided tours to some of the best biking and hiking trails in Rwanda, 
       including the Congo Nile Divide Trail, Lake Muhazi, and the Musanze Twin Lakes 123. These trails offer a range of 
       difficulty levels, from easy to challenging, and are suitable for both novice and experienced bikers and hikers.
        Our experienced guides will help you navigate the trails and provide you with a comprehensive understanding of 
        the history and culture of Rwanda. We are committed to providing you with a safe and memorable experience that 
        will leave you with unforgettable memories of Rwanda's natural beauty. 
      Please contact us to learn more about our services and to book your tour today`,
      gallery: [
        {
          image:
            "https://res.cloudinary.com/dnqhrr6jk/image/upload/f_auto,q_auto/v1/Lewen%20Tours/ahugqhrgdlsl6m0jkttt",
          public_id: "man-on-bike",
        },
        {
          image:
            "https://res.cloudinary.com/dnqhrr6jk/image/upload/f_auto,q_auto/v1/Lewen%20Tours/npgxw5687jpviewtswf9",
          public_id: "hiking-main-forest",
        },
        {
          image:
            "https://res.cloudinary.com/dnqhrr6jk/image/upload/f_auto,q_auto/v1/Lewen%20Tours/wzayqwg5lxzw3ih2hbop",
          public_id: "people-on-bike",
        },
        {
          image:
            "https://res.cloudinary.com/delpedjbc/image/upload/f_auto,q_auto/v1/lewen%20tours/irjvr5bnlyoeuxtmsj6t",
          public_id: "man-riding-bike-on-mountain",
        },
        {
          image:
            "https://res.cloudinary.com/dnqhrr6jk/image/upload/f_auto,q_auto/v1/Lewen%20Tours/qebd75ot4yazk4hwbkbo",
          public_id: "view-wild-gibbon",
        },
        {
          image:
            "https://res.cloudinary.com/delpedjbc/image/upload/f_auto,q_auto/v1/lewen%20tours/cq3xpia7fgmzch3n9a2y",
          public_id: "view-gibbon-apes-rain",
        },
      ],
    },
  },
  {
    id: 5,
    travel_image:
      "https://res.cloudinary.com/delpedjbc/image/upload/f_auto,q_auto/v1/lewen%20tours/20211221_124938_lyyd5a",
    travel_title: "City Tour",
    travel_text:
      "Delve into the vibrant tapestry of Kigali, a city that pulsates with life and energy.",
    trave_details: {
      link: "city-tour",
      background_image:
        "/images/GolliraTrekking/view-wild-gibbon-apes-nature.jpg",
      description: `
          Rwanda is home to a variety of cities, each with its own unique charm and character. Kigali, 
          the capital city, is a bustling metropolis with a vibrant arts and culture scene. Gisenyi, 
          a city on the shores of Lake Kivu, is a popular resort town known for its beautiful scenery and relaxed atmosphere.
          Ruhengeri, located in the Musanze district, is the gateway to the Volcanoes National Park, home to the endangered mountain gorilla. Byumba, 
          in the Northern Province, is a historic city with a rich cultural heritage. 
          ${"<br />"} Whether you're looking for a bustling city with plenty to see and do, a relaxing resort town to escape the hustle and bustle, or a historic city steeped in culture, Rwanda has a city to suit your taste.`,
      gallery: [
        {
          image:
            "https://res.cloudinary.com/delpedjbc/image/upload/f_auto,q_auto/v1/lewen%20tours/20230317_120514_mxqfnz",
          public_id: "city-as-it-is1",
        },
        {
          image:
            "https://res.cloudinary.com/delpedjbc/image/upload/f_auto,q_auto/v1/lewen%20tours/20230322_190534_kkdqbg",
          public_id: "city-tall-image",
        },
        {
          image:
            "https://res.cloudinary.com/delpedjbc/image/upload/f_auto,q_auto/v1/lewen%20tours/hyvymcz0w4gc2f4pha64",
          public_id: "city-amahoro-stadiusm",
        },
        {
          image:
            "https://res.cloudinary.com/delpedjbc/image/upload/f_auto,q_auto/v1/lewen%20tours/20230317_120457_vcj1lg",
          public_id: "kigali-city-streets",
        },
        {
          image:
            "https://res.cloudinary.com/delpedjbc/image/upload/f_auto,q_auto/v1/lewen%20tours/20221226_130545_ld6nhu",
          public_id: "kigali-vision-city-layout",
        },
        {
          image:
            "https://res.cloudinary.com/delpedjbc/image/upload/f_auto,q_auto/v1/lewen%20tours/ltvcqpnhaohacnevhvxy",
          public_id: "lewen tours/20221226_130545_ld6nhu",
        },
      ],
    },
  },
  {
    id: 7,
    travel_image:
      "https://res.cloudinary.com/delpedjbc/image/upload/f_auto,q_auto/v1/lewen%20tours/q1cmzdg5v1bs95emukxy",
    travel_title: "Home stay",
    travel_text:
      "Seeking an authentic and immersive travel experience? Look no further than a homestay in Rwanda. ",
    trave_details: {
      link: "home-stay",
      background_image:
        "/images/GolliraTrekking/view-wild-gibbon-apes-nature.jpg",
      description: ` Rwanda is a country of stunning natural beauty, and there's no better way to experience it than by 
          staying with a local family. Our travel website offers a unique opportunity to immerse yourself in the local culture and 
          lifestyle by booking a homestay in Rwanda. We provide a range of homestay options throughout the country, including in Kigali,
          Musanze, and Gisenyi. Our homestays are hosted by friendly and welcoming families who will make you feel at home and 
          provide you with a unique insight into the local way of life. You can choose from a range of accommodation options, 
          including private rooms and shared spaces, and enjoy authentic Rwandan meals prepared by your host family.
           We are committed to providing you with a safe and comfortable experience that will leave you with unforgettable memories of Rwanda's natural beauty and hospitality. 
          Please contact us to learn more about our services and to book your homestay today`,
      gallery: [
        {
          image:
            "https://res.cloudinary.com/delpedjbc/image/upload/f_auto,q_auto/v1/lewen%20tours/twaonh3ruhzngfd1ypua",
          public_id: "cute-small-chimpan",
        },
        {
          image:
            "https://res.cloudinary.com/delpedjbc/image/upload/f_auto,q_auto/v1/lewen%20tours/pj09h2fxkkoktr8pcazu",
          public_id: "mountain-gorillas-gorilla-beringei",
        },
        {
          image:
            "https://res.cloudinary.com/delpedjbc/image/upload/f_auto,q_auto/v1/lewen%20tours/psxad7mnjolzu51uwdhr",
          public_id: "view-gibbon-apes",
        },
        {
          image:
            "https://res.cloudinary.com/delpedjbc/image/upload/f_auto,q_auto/v1/lewen%20tours/b1fc4mcw6widjjfmzbax",
          public_id: "view-gorilla-sitting",
        },
        {
          image:
            "https://res.cloudinary.com/delpedjbc/image/upload/f_auto,q_auto/v1/lewen%20tours/xn2b5ztj0xkhieurswk9",
          public_id: "view-wild-gibbon",
        },
        {
          image:
            "https://res.cloudinary.com/delpedjbc/image/upload/f_auto,q_auto/v1/lewen%20tours/xwujdu8xmvrupjjtyp9p",
          public_id: "view-gibbon-apes-rain",
        },
      ],
    },
  },
  {
    id: 8,
    rating:5,
    travel_image:
      "https://res.cloudinary.com/dnqhrr6jk/image/upload/f_auto,q_auto/v1/Lewen%20Tours/jdmkjjdj0invwizmklk8",
    travel_title: "National Parks",
    travel_text: 'In the heart of Central Africa, so high up that you shiver more than you sweat,” wrote the eminent primatologist Dian Fossey, “are great, old volcanoes towering almost 15,000 feet, and nearly covered with rich, green rainforest - the Virungas.',
    trave_details:{
      link: "National Parks",
      background_image:
        "https://res.cloudinary.com/dnqhrr6jk/image/upload/f_auto,q_auto/v1/Lewen%20Tours/jdmkjjdj0invwizmklk8",
      description: `The park's true crown jewels, however, are the majestic mountain gorillas. Tracking these gentle giants through the rainforest is an experience unlike any other. Witnessing their family dynamics, the playful youngsters and the silverback's watchful gaze, is a humbling reminder of our connection to the natural world.`,
      gallery: [],
    }
  },
];

export default top_travelist;