import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ContextProvider from "./Context/ContextProvider";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ContextProvider>
    <App />
    <ToastContainer 
        position= "bottom-center"
        autoClose= {5000}
        theme="dark"
      />
  </ContextProvider>
);

reportWebVitals();
