import React from 'react'
import Stars from '../BasicComponents/Stars';
import { Link } from "react-router-dom";

const AccomodationCard = ({ accomodation }) => {
  return (
    <div className="hotel_card">
      <div className="hotel_image">
        <img src={accomodation.image} alt="" />
      </div>
      <div className="hotel_content">
        <h3>{accomodation.title}</h3>
        <span className="loc">
          <i className="fa-solid fa-location-dot"></i>
          <span>{accomodation.location}</span>
        </span>
        <div className="review_star">
          <Stars color="blue"/>
          <span>Reviews ({accomodation.reviewsNo})</span>
        </div>
        <div className="describes">
          <i className="fa-regular fa-circle-check"></i>
          <p> {accomodation.description}</p>
        </div>
        <div className="footer">
          <div className="text">
            <span>{accomodation.category}</span>|
            <i className="fa-solid fa-wifi" title="Free WIFI"></i>
            <i className="fa-solid fa-mug-hot" title="Break Fast"></i>
            <i className="fa-solid fa-car" title="Free Parking"></i>
          </div>
          <div className="footer_links">
            <Link to="/booking" className="submit_button">
              Book Now
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccomodationCard