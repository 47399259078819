const gallery_images = [
    {
      image_url:"https://res.cloudinary.com/dnqhrr6jk/image/upload/f_auto,q_auto/v1/Lewen%20Tours/garelly1_af9wul",
      image_id:1,
      category:"National Park"
    },
    {
      image_url:"https://res.cloudinary.com/dnqhrr6jk/image/upload/f_auto,q_auto/v1/Lewen%20Tours/garelly2_rg8kjj",
      image_id:2,
      category:"Hotel"
    },
    {
      image_url:"https://res.cloudinary.com/dnqhrr6jk/image/upload/f_auto,q_auto/v1/Lewen%20Tours/garelly3_lwebve",
      image_id:3,
      category:"National Park"
    },
    {
      image_url:"https://res.cloudinary.com/dnqhrr6jk/image/upload/f_auto,q_auto/v1/Lewen%20Tours/garelly4_znofsn",
      image_id:4,
      category:"National Park"
    },
    {
      image_url:"/images/Travels/garelly5.jpeg",
      image_id:5,
      category:"National Park"
    },
    {
      image_url:"https://res.cloudinary.com/dnqhrr6jk/image/upload/f_auto,q_auto/v1/Lewen%20Tours/garelly6_oq3laq",
      image_id:6,
      category:"National Park"
    }
  ];

  export default gallery_images;