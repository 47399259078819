const destinations = [
    {
      id: 1,
      rating:5,
      dest_image:
        "https://res.cloudinary.com/dnqhrr6jk/image/upload/f_auto,q_auto/v1/Lewen%20Tours/k29vbkpgyq9fmhew3ldj",
      title: "Kigali",
      body: "A waterfront town on the shores of Lake Kivu, with red sandy beaches, warm clean water and an easygoing tropical character.",
      category: "Towns",
      dest_details:{
        link: "kigali",
        background_image:
          "https://res.cloudinary.com/dnqhrr6jk/image/upload/f_auto,q_auto/v1/Lewen%20Tours/ad0unircafpnqt58vmyy",
        description: `Step into the heart of Rwanda, where Kigali, its capital, pulsates with a captivating blend of modernity and cultural heritage. Nestled amidst verdant hills and serene lakes, this city awakens the senses with its vibrant energy and warm hospitality.
        <br /><br />
        As you explore the city's bustling streets, you'll encounter a harmonious fusion of old and new. The towering Kigali Convention Centre stands as a symbol of Rwanda's progress, while the Kigali Genocide Memorial serves as a poignant reminder of the country's resilience. Immerse yourself in the lively ambiance of Nyamirambo, the city's nightlife district, where traditional rhythms and authentic cuisine paint a vibrant tapestry of Rwandan culture.`,
        gallery: [
          {
            image:
              "https://res.cloudinary.com/dnqhrr6jk/image/upload/f_auto,q_auto/v1/Lewen%20Tours/ad0unircafpnqt58vmyy",
            public_id: "cute-small-gorilla",
          },
          {
            image:
              "https://res.cloudinary.com/delpedjbc/image/upload/f_auto,q_auto/v1/lewen%20tours/a6b6iphrujoruqmzjaaq",
            public_id: "kigali-national-parliament",
          },
          {
            image:
              "https://res.cloudinary.com/delpedjbc/image/upload/f_auto,q_auto/v1/lewen%20tours/20221226_130545_ld6nhu",
            public_id: "view-gibbon-apes",
          },
          {
            image:
              "https://res.cloudinary.com/delpedjbc/image/upload/f_auto,q_auto/v1/lewen%20tours/20211221_124938_lyyd5a",
            public_id: "view-gorilla-sitting",
          },
          {
            image:
              "https://res.cloudinary.com/delpedjbc/image/upload/f_auto,q_auto/v1/lewen%20tours/i8jfglxpvjcvfuw9nwnc",
            public_id: "view-wild-gibbon",
          },
          {
            image:
              "https://res.cloudinary.com/delpedjbc/image/upload/f_auto,q_auto/v1/lewen%20tours/pe49tvj9usf9rmyjiup1",
            public_id: "view-gibbon-apes-rain",
          },
        ],
      }
    },
    {
      id: 2,
      rating:4,
      dest_image:
        "https://res.cloudinary.com/dnqhrr6jk/image/upload/f_auto,q_auto/v1/Lewen%20Tours/w5yhdfwhzlfz9pymfxuj",
      title: "Rubavu",
      body: "The capital city is pleasantly low key yet dynamic and progressive. It's green, clean and safe with meaningful culture and remarkable drive.",
      category: "Towns",
      dest_details:{
        link: "Rubavu",
        background_image:
          "https://res.cloudinary.com/dnqhrr6jk/image/upload/f_auto,q_auto/v1/Lewen%20Tours/w5yhdfwhzlfz9pymfxuj",
        description: `A waterfront town on the shores of Lake Kivu, Rubavu is an hour away from the Volcanoes National Park, and makes a good stopover on the way to or from Nyungwe, or somewhere to relax after gorilla tracking.
        Rubavu, as well as other spots along Lake Kivu, has red sandy beaches, warm, clean water and an easygoing tropical character. It is a great place to unwind, soak up the tranquil vibe, watch the birds and enjoy various watersports.`,
        gallery: [
          {
            image:
              "https://res.cloudinary.com/delpedjbc/image/upload/f_auto,q_auto/v1/lewen%20tours/Visit-Rwanda-Rubavu-Beach-Avenue-1920x1280_qmxf31",
            public_id: "road-captured-rubavu",
          },
          {
            image:
              "https://res.cloudinary.com/delpedjbc/image/upload/f_auto,q_auto/v1/lewen%20tours/Rwanda-Origin_f8sjim",
            public_id: "lake-kivu-rubavu-view",
          },
          {
            image:
              "https://res.cloudinary.com/delpedjbc/image/upload/f_auto,q_auto/v1/lewen%20tours/Town-Areas-in-Rubavu-District_aqyrpo",
            public_id: "street view",
          }
        ],
      }
    },
    {
      id: 3,
      rating:4,
      dest_image:
        "https://res.cloudinary.com/dnqhrr6jk/image/upload/f_auto,q_auto/v1/Lewen%20Tours/rzashnaoobb7g851bkgt",
      title: "Karongi",
      body: "A popular beach retreat on the shores of Lake Kivu with majestic vistas, a tranquil atmosphere and easy access from Kigali.",
      category: "Towns",
      dest_details:{
        link: "Karongi",
        background_image:
          "https://res.cloudinary.com/dnqhrr6jk/image/upload/f_auto,q_auto/v1/Lewen%20Tours/rzashnaoobb7g851bkgt",
        description: `In the heart of Rwanda's scenic Western Province, nestled amidst rolling hills and the captivating expanse of Lake Kivu, lies Karongi, a gem of tranquility and adventure. Cast aside the worries of everyday life and immerse yourself in the serene beauty of this charming town, where the rhythm of life moves at a gentle pace.
        <br /><br />
        As you arrive in Karongi, the refreshing lake breeze greets you, carrying with it the essence of a place untouched by the hustle and bustle of the modern world. Explore the town's charming streets lined with local shops, cafes, and restaurants, where the aroma of freshly brewed coffee and traditional Rwandan cuisine fills the air.`,
        gallery: [
          {
            image:
              "https://res.cloudinary.com/dnqhrr6jk/image/upload/f_auto,q_auto/v1/Lewen%20Tours/rzashnaoobb7g851bkgt",
            public_id: "road-captured-rubavu",
          },
          {
            image:
              "https://res.cloudinary.com/delpedjbc/image/upload/f_auto,q_auto/v1/lewen%20tours/h4h2tth8osjdof5gdrww",
            public_id: "lake-kivu-rubavu-view",
          },
          {
            image:
              "https://res.cloudinary.com/delpedjbc/image/upload/f_auto,q_auto/v1/lewen%20tours/e4bg8sz01qc6eippehsh",
            public_id: "street view",
          }
        ],
      }
    },
    {
      id: 4,
      rating:4,
      dest_image:
        "https://res.cloudinary.com/dnqhrr6jk/image/upload/f_auto,q_auto/v1/Lewen%20Tours/dlwlwfqnxcqekzguogbx",
      title: "Musanze",
      body: "Close to the Volcanoes National Park, Musanze is a hassle-free and buzzing city, with plenty of choice for eating out, ATMs to withdraw cash, vibrant nightlife, markets and artisanal trades.",
      category: "Towns",
      dest_details:{
        link: "Nyanza",
        background_image:
          "https://res.cloudinary.com/dnqhrr6jk/image/upload/f_auto,q_auto/v1/Lewen%20Tours/dlwlwfqnxcqekzguogbx",
        description: `Nestled amidst the towering peaks of the Virunga Mountains, Musanze, a captivating town in northern Rwanda, serves as a gateway to unparalleled natural wonders and unforgettable encounters with the majestic mountain gorillas. Embark on an expedition that will leave you awestruck by the beauty of the African wilderness and the heartwarming spirit of the local community.
        <br /><br />
        Step into the heart of Volcanoes National Park, home to the endangered mountain gorillas, and embark on a thrilling gorilla trekking experience. As you venture into the lush rainforest, guided by experienced rangers, anticipation builds with the possibility of encountering these magnificent primates in their natural habitat.`,
        gallery: [
          {
            image:
              "https://res.cloudinary.com/dnqhrr6jk/image/upload/f_auto,q_auto/v1/Lewen%20Tours/dlwlwfqnxcqekzguogbx",
            public_id: "road-captured-rubavu",
          },
          {
            image:
              "https://res.cloudinary.com/delpedjbc/image/upload/f_auto,q_auto/v1/lewen%20tours/dq1iaux5uu7hww8u4bsx",
            public_id: "nyanza-cows",
          },
          {
            image:
              "https://res.cloudinary.com/delpedjbc/image/upload/f_auto,q_auto/v1/lewen%20tours/bmru3bsir6gibifssxu6",
            public_id: "nyanza-traditional-dance",
          },
          {
            image:
              "https://res.cloudinary.com/delpedjbc/image/upload/f_auto,q_auto/v1/lewen%20tours/u7cfk753soxyfutu3flx",
            public_id: "nyanza-kingship-home",
          },
          {
            image:
              "https://res.cloudinary.com/delpedjbc/image/upload/f_auto,q_auto/v1/lewen%20tours/s5nm3rzbnlqjoz19gi3r",
            public_id: "nyanza-kingship-inside",
          },
          {
            image:
              "https://res.cloudinary.com/delpedjbc/image/upload/f_auto,q_auto/v1/lewen%20tours/d41inzuvomhoq06gfsh0",
            public_id: "nyanza-outside-view",
          }
        ],
      }
    },
    {
      id: 5,
      rating:3,
      dest_image:
        "https://res.cloudinary.com/dnqhrr6jk/image/upload/f_auto,q_auto/v1/Lewen%20Tours/ncbg1vei7q2nudzl8hw8",
      title: "Nyanza",
      body: "Home to the King's Palace - a reconstruction of the traditional royal residence, a beautifully-crafted thatched dwelling shaped like a beehive.",
      category: "Towns",
      dest_details:{
        link: "Nyanza",
        background_image:
          "https://res.cloudinary.com/dnqhrr6jk/image/upload/f_auto,q_auto/v1/Lewen%20Tours/ncbg1vei7q2nudzl8hw8",
        description: `The administrative capital of the Southern Province, Nyanza is the eighth largest city in Rwanda. The main attraction of the region lies in its cultural heritage, which undoubtedly merits a stopover en route to Nyungwe National Park.
        <br /><br />
        Well worth a visit is the King’s Palace, a reconstruction of the traditional royal residence, a beautifully-crafted thatched dwelling shaped like a beehive. At the back live a few long-horned Ankole cattle, descended from the king’s herd, whose keepers carefully tend and sing to them.`,
        gallery: [
          {
            image:
              "https://res.cloudinary.com/dnqhrr6jk/image/upload/f_auto,q_auto/v1/Lewen%20Tours/ncbg1vei7q2nudzl8hw8",
            public_id: "kings-palace-nyanza",
          },
          {
            image:
              "https://res.cloudinary.com/delpedjbc/image/upload/f_auto,q_auto/v1/lewen%20tours/o6ugy6fwvdnvzgaf7oa3",
            public_id: "nyanza-cows",
          },
          {
            image:
              "https://res.cloudinary.com/delpedjbc/image/upload/f_auto,q_auto/v1/lewen%20tours/a7vrl1hzpwhsmywqd0pn",
            public_id: "nyanza-traditional-dance",
          },
          {
            image:
              "https://res.cloudinary.com/delpedjbc/image/upload/f_auto,q_auto/v1/lewen%20tours/xjgbrk7fncwjeb2fiwme",
            public_id: "nyanza-kingship-home",
          },
          {
            image:
              "https://res.cloudinary.com/delpedjbc/image/upload/f_auto,q_auto/v1/lewen%20tours/qabfvdpi8ygxtyjs2hko",
            public_id: "nyanza-kingship-inside",
          },
          {
            image:
              "https://res.cloudinary.com/delpedjbc/image/upload/f_auto,q_auto/v1/lewen%20tours/qgwcclgwaxo8ccwf7uyr",
            public_id: "nyanza-outside-view",
          }
        ],
      }
    },
    {
      id: 6,
      rating:3,
      dest_image:
        "https://res.cloudinary.com/dnqhrr6jk/image/upload/f_auto,q_auto/v1/Lewen%20Tours/ocntd4vtywijbihiurmw",
      title: "Rusizi",
      body: "A small town on the border with the Democratic Republic of the Congo and the closest town to Nyungwe National Park. Budget accommodation here will appeal to self-drive visitors to the National Park.",
      category: "Towns",
      dest_details:{
        link: "Nyanza",
        background_image:
          "https://res.cloudinary.com/dnqhrr6jk/image/upload/f_auto,q_auto/v1/Lewen%20Tours/ocntd4vtywijbihiurmw",
        description: `A small town on the border with the DRC, with views across to the Congolese city of Bukavu.
        <br/><br/>
        Rusizi is the closest town to Nyungwe National Park, which is about an hour’s drive away. Budget accommodation here will appeal to self-drive visitors to the National Park.`,
        gallery: [
          {
            image:
              "https://res.cloudinary.com/dnqhrr6jk/image/upload/f_auto,q_auto/v1/Lewen%20Tours/ocntd4vtywijbihiurmw",
            public_id: "kings-palace-nyanza",
          },
          {
            image:
              "https://res.cloudinary.com/delpedjbc/image/upload/f_auto,q_auto/v1/lewen%20tours/khdgopazsh1qggok0dyw",
            public_id: "rusizi-natian-park-hippopatamus",
          },
          {
            image:
              "https://res.cloudinary.com/delpedjbc/image/upload/f_auto,q_auto/v1/lewen%20tours/qp0iyvduybehzehdqomt",
            public_id: "rusizi-buildings",
          }
        ],
      }
    },
    {
      id: 7,
      rating:4,
      dest_image:
        "https://res.cloudinary.com/dnqhrr6jk/image/upload/f_auto,q_auto/v1/Lewen%20Tours/gluvl5rnynxhxdlafn62",
      title: "Huye",
      body: "Peaceful and compact, Huye was founded in the early colonial era. Today is a centre of academia, as well as housing the Ethnographic Museum.",
      category: "Towns",
      dest_details:{
        link: "Huye",
        background_image:
          "https://res.cloudinary.com/dnqhrr6jk/image/upload/f_auto,q_auto/v1/Lewen%20Tours/gluvl5rnynxhxdlafn62",
        description: `Step into the heart of Rwanda's Southern Province, where Huye, a charming town steeped in history and culture, awaits your discovery. Nestled amidst rolling hills and verdant landscapes, Huye offers a tranquil escape from the hustle and bustle of modern life, inviting you to immerse yourself in the authentic spirit of Rwanda.
        <br/><br/>
        Immerse yourself in the spiritual essence of Rwanda by visiting the King's Palace, a UNESCO World Heritage Site that served as the royal residence of the former Rwandan kings. Wander through the serene gardens and imagine the grandeur of the past, while reflecting on the enduring legacy of Rwanda's monarchs.`,
        gallery: [
          {
            image:
              "https://res.cloudinary.com/dnqhrr6jk/image/upload/f_auto,q_auto/v1/Lewen%20Tours/gluvl5rnynxhxdlafn62",
            public_id: "kings-palace-nyanza",
          },
          {
            image:
              "https://res.cloudinary.com/delpedjbc/image/upload/f_auto,q_auto/v1/lewen%20tours/gk9f1qvcnieyhf0epatu",
            public_id: "rusizi-natian-park-hippopatamus",
          },
          {
            image:
              "https://res.cloudinary.com/delpedjbc/image/upload/f_auto,q_auto/v1/lewen%20tours/feycyxcs3juybojkjoog",
            public_id: "rusizi-buildings",
          }
        ],
      }
    },
    {
      id: 8,
      rating:5,
      dest_image:
        "https://res.cloudinary.com/dnqhrr6jk/image/upload/f_auto,q_auto/v1/Lewen%20Tours/jdmkjjdj0invwizmklk8",
      title: "Volcanoes National Park",
      body: '"In the heart of Central Africa, so high up that you shiver more than you sweat,” wrote the eminent primatologist Dian Fossey, “are great, old volcanoes towering almost 15,000 feet, and nearly covered with rich, green rainforest - the Virungas."',
      category: "National Parks",
      dest_details:{
        link: "Volcanoes National Park",
        background_image:
          "https://res.cloudinary.com/dnqhrr6jk/image/upload/f_auto,q_auto/v1/Lewen%20Tours/jdmkjjdj0invwizmklk8",
        description: `Volcanoes National Park, nestled in the Virunga Mountains along Rwanda's northwestern border, is a hiker's paradise and a wildlife haven. Hike through bamboo forests and open grasslands, feeling the cool mountain air invigorate your senses. Climb Mount Karisimbi, the highest peak in Rwanda, and bask in panoramic views that stretch as far as the eye can see.
        <br/><br/>
        The park's true crown jewels, however, are the majestic mountain gorillas. Tracking these gentle giants through the rainforest is an experience unlike any other. Witnessing their family dynamics, the playful youngsters and the silverback's watchful gaze, is a humbling reminder of our connection to the natural world.`,
        gallery: [
          {
            image:
              "https://res.cloudinary.com/dnqhrr6jk/image/upload/f_auto,q_auto/v1/Lewen%20Tours/jdmkjjdj0invwizmklk8",
            public_id: "kings-palace-nyanza",
          },
          {
            image:
              "https://res.cloudinary.com/delpedjbc/image/upload/f_auto,q_auto/v1/lewen%20tours/dm2ykl8hpdwm03jgipyf",
            public_id: "rusizi-natian-park-hippopatamus",
          },
          {
            image:
              "https://res.cloudinary.com/delpedjbc/image/upload/f_auto,q_auto/v1/lewen%20tours/xrkrlz9y6jfpjbhnqntg",
            public_id: "rusizi-buildings",
          }
        ],
      }
    },
    {
      id: 9,
      rating:3,
      dest_image:
        "https://res.cloudinary.com/dnqhrr6jk/image/upload/f_auto,q_auto/v1/Lewen%20Tours/rfpcpx1cpknqy6kjks0t",
      title: "Akagera National Park",
      body: "The relatively warm and low-lying plains of Akagera comprise savannah, woodland, wetland and a dozen lakes. In partnership with African Parks, we have reintroduced lions and rhinos, meaning once again visitors can hope to see the Big Five on safari drives.",
      category: "National Parks",
      dest_details:{
        link: "Akagera National Park",
        background_image:
          "https://res.cloudinary.com/dnqhrr6jk/image/upload/f_auto,q_auto/v1/Lewen%20Tours/rfpcpx1cpknqy6kjks0t",
        description: `Imagine this: you're perched high on a safari vehicle, the golden African sun warming your face as you scan the vast expanse of savanna grasslands. Acacia trees cast dappled shadows across the plains, while in the distance, a herd of zebras graze peacefully, their black and white stripes shimmering in the heat. Welcome to Akagera National Park, Rwanda's premier wildlife sanctuary and a haven for adventure seekers who yearn to experience the raw beauty and thrilling encounters of the African safari.
        <br/><br/>
        Akagera is a tapestry of diverse landscapes, from rolling savannas and acacia woodlands to shimmering lakes and papyrus swamps. This rich ecosystem pulsates with life, providing a vital habitat for over 8,000 large mammals and an astounding 500 bird species.`,
        gallery: [
          {
            image:
              "https://res.cloudinary.com/dnqhrr6jk/image/upload/f_auto,q_auto/v1/Lewen%20Tours/rfpcpx1cpknqy6kjks0t",
            public_id: "kings-palace-nyanza",
          },
          {
            image:
              "https://res.cloudinary.com/delpedjbc/image/upload/f_auto,q_auto/v1/lewen%20tours/v8tglxsymbpc3q1rrqhz",
            public_id: "rusizi-natian-park-hippopatamus",
          },
          {
            image:
              "https://res.cloudinary.com/delpedjbc/image/upload/f_auto,q_auto/v1/lewen%20tours/wgx9xnrthe3mrqzcqm8m",
            public_id: "rusizi-buildings",
          }
        ],
      }
    },
    {
      id: 10,
      rating:5,
      dest_image:
        "https://res.cloudinary.com/dnqhrr6jk/image/upload/f_auto,q_auto/v1/Lewen%20Tours/kwa54kebmzslcvumaoi9",
      title: "Nyungwe National Park",
      body: "One of the oldest rainforests in Africa, Nyungwe is rich in biodiversity and spectacularly beautiful. The mountainous region is teaming with wildlife, including a small population of chimpanzees as well as 12 other species of primate.",
      category: "National Parks",
      dest_details:{
        link: "Nyungwe National Park",
        background_image:
          "https://res.cloudinary.com/dnqhrr6jk/image/upload/f_auto,q_auto/v1/Lewen%20Tours/kwa54kebmzslcvumaoi9",
        description: `Nyungwe is a tapestry of breathtaking landscapes: rolling hills cloaked in emerald foliage, bamboo forests swaying in the breeze, and shimmering waterfalls cascading down moss-covered rocks. This ancient ecosystem pulsates with life, providing a haven for over 1,000 plant species, 310 bird species, and 13 primate species, including the captivating chimpanzee.
        <br/><br/>
        Embark on a chimpanzee trekking adventure, a once-in-a-lifetime experience. Track these playful primates through the dense rainforest, witness their complex social interactions, and marvel at their intelligence and grace. Every encounter is a reminder of our own connection to the natural world and the shared ancestry we hold with these magnificent creatures.`,
        gallery: [
          {
            image:
              "https://res.cloudinary.com/dnqhrr6jk/image/upload/f_auto,q_auto/v1/Lewen%20Tours/kwa54kebmzslcvumaoi9",
            public_id: "kings-palace-nyanza",
          },
          {
            image:
              "https://res.cloudinary.com/delpedjbc/image/upload/f_auto,q_auto/v1/lewen%20tours/doez51dmmjcalrkc1gh6",
            public_id: "rusizi-natian-park-hippopatamus",
          },
          {
            image:
              "https://res.cloudinary.com/delpedjbc/image/upload/f_auto,q_auto/v1/lewen%20tours/iaseaj8l5ysfa7qrtwzn",
            public_id: "rusizi-buildings",
          }
        ],
      }
    },
    {
      id: 11,
      rating:3,
      dest_image:
        "https://res.cloudinary.com/dnqhrr6jk/image/upload/f_auto,q_auto/v1/Lewen%20Tours/o07uuildao9ovixuok87",
      title: "Gishwati Mukura National Park",
      body: "Rwanda's fourth National Park, Gishwati Mukura is made up of two separate forests - the larger Gishwati and small Mukura. It is home to chimpanzees, golden, blue and L'Hoest's monkeys as well as a host of birds and smaller animals.",
      category: "National Parks",
      dest_details:{
        link: "Gishwati Mukura National Park",
        background_image:
          "https://res.cloudinary.com/dnqhrr6jk/image/upload/f_auto,q_auto/v1/Lewen%20Tours/o07uuildao9ovixuok87",
        description: `Nestled amidst the rolling hills of western Rwanda, where the verdant tapestry of forests meets the sparkling expanse of Lake Kivu, lies Gishwati Mukura National Park. This jewel of biodiversity, born from the union of the Gishwati and Mukura forests, pulsates with life and whispers ancient secrets of resilience and renewal.
        <br/><br/>
        More than just a haven for nature lovers, Gishwati Mukura National Park is a symbol of hope. Once severely fragmented by human intervention, the forests are undergoing a remarkable restoration, showcasing the power of conservation and the unwavering spirit of the Rwandan people.
        <br/><br/>
        By visiting Gishwati Mukura National Park, you become a part of this inspiring story. Your presence contributes to the ongoing conservation efforts, helping to secure the future of this precious ecosystem and its diverse inhabitants.`,
        gallery: [
          {
            image:
              "https://res.cloudinary.com/dnqhrr6jk/image/upload/f_auto,q_auto/v1/Lewen%20Tours/o07uuildao9ovixuok87",
            public_id: "kings-palace-nyanza",
          },
          {
            image:
              "https://res.cloudinary.com/delpedjbc/image/upload/f_auto,q_auto/v1/lewen%20tours/lu1e6mqeizo6bbtcv7wv",
            public_id: "rusizi-natian-park-hippopatamus",
          },
          {
            image:
              "https://res.cloudinary.com/delpedjbc/image/upload/f_auto,q_auto/v1/lewen%20tours/ahb6pnkviskmon4g8io4",
            public_id: "rusizi-buildings",
          }
        ],
      }
    },
    {
      id: 12,
      rating:5,
      dest_image:
        "https://res.cloudinary.com/delpedjbc/image/upload/f_auto,q_auto/v1/lewen%20tours/wsmxkbc3g63z2thfggfn",
      title: "Lake Kivu",
      body: "Part of Africa's Great Rift Valley, Lake Kivu is dotted with islands and inlets along its shoreline, with charming beach resorts, spectacular vistas and plenty of opportunities for hiking and cycling.",
      category: "Other",
      dest_details:{
        link: "Lake Kivu",
        background_image:
          "https://res.cloudinary.com/delpedjbc/image/upload/f_auto,q_auto/v1/lewen%20tours/wsmxkbc3g63z2thfggfn",
        description: `A small town on the border with the DRC, with views across to the Congolese city of Bukavu.
        <br/><br/>
        Rusizi is the closest town to Nyungwe National Park, which is about an hour’s drive away. Budget accommodation here will appeal to self-drive visitors to the National Park.`,
        gallery: [
          {
            image:
              "https://res.cloudinary.com/delpedjbc/image/upload/f_auto,q_auto/v1/lewen%20tours/wsmxkbc3g63z2thfggfn",
            public_id: "kings-palace-nyanza",
          },
          {
            image:
              "https://res.cloudinary.com/delpedjbc/image/upload/f_auto,q_auto/v1/lewen%20tours/h1vvhmc4zyyzwccnbesb",
            public_id: "rusizi-natian-park-hippopatamus",
          },
          {
            image:
              "https://res.cloudinary.com/delpedjbc/image/upload/f_auto,q_auto/v1/lewen%20tours/npe0jsovrgcmjvleyaxd",
            public_id: "rusizi-buildings",
          }
        ],
      }
    },
  ]

  export default destinations;
