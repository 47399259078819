import React from 'react';
import TopContact from './TopContact';
import Navigation from './Navigation';

function Header() {
  return (
    <>
      <TopContact />
      <Navigation />
    </>
  );
}

export default Header